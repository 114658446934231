import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <div className="flex justify-center items-center p-4">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{message}</span>
      </div>
    </div>
  );
};

export default ErrorMessage;